<template>
  <Track ref="trackRef"
    :iProp="trackProps"
    :loadStatus="loadStatus"
    :selecteds="selecteds" />
</template>

<script>
import Track from '@/components/Track';
import { sleep } from '@/utils/sleep';
import dayjs from 'dayjs';

export default {
  // `name`与路由保持一致
  name: 'TrackPlayback',
  components: {
    Track
  },
  data() {
    return {
      trackProps: {},
      loadStatus: false,
      selecteds: [],
    };
  },
  watch: {
    $route: {
      immediate: true,
      async handler(newRoute, oldRoute) {
        const { vehicle } = newRoute.params;
        if (!vehicle) return;
        await this.$nextTick();

        const { trackRef } = this.$refs;

        if (!trackRef) return;

        trackRef.setSearchLoading(true);
        // await sleep(1000);

        this.trackProps = {
          vehicleId: vehicle.V, // 车辆Id
          filterTime: 5, // 停车时间
          posStatus: 1, // 是否过滤不定位数据,0:过滤,1:不过滤
          plate: vehicle.P, // 设备名称
          stopStatus: 0,  // 是否过滤停车数据? 0:过滤, 1:不过滤
          // 开始时间
          beginTime: dayjs().format('YYYY-MM-DD 00:00:00'),
          // 结束时间
          endTime: dayjs().format('YYYY-MM-DD 23:59:59'),
          stopFlag: 0, // 是否过滤行驶数据
        };

        this.selecteds = [{
          label: vehicle.P,
          id: vehicle.V,
        }];

        this.loadStatus = true;

        await this.$nextTick();
        trackRef.checkLoadStatus();

      }
    },
  },

}
</script>